import React, { useEffect } from "react";

function Knowmorepage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <div className='bg-slate-100'>
            <section
                className='px-8 lg:px-48 pb-[30%] lg:pb-[20%]'
                style={{
                    backgroundImage: "url('/solarbg.jpg')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    color: 'white',
                }}
            >
                <h3 className='uppercase text-white text-xl pt-16 pb-6 lg:text-3xl'>
                    Who are we?
                </h3>
                <h1 className='text-4xl lg:text-6xl'>
                    Your Partner <br /> in Climate <br /> Action
                </h1>
            </section>

            <section className='px-8 lg:px-48'>
                <div className='relative bg-white flex flex-col items-center justify-center mt-[-20px] lg:mt-[-50px] rounded-lg'>
                    <div className='absolute bottom-full z-10 flex flex-col justify-center items-center w-24 aspect-square bg-white translate-y-1/2 rounded-full'>
                        <img
                            src='logoadd.png'
                            alt='a green leaf'
                        />
                        <span className='text-sm font-semibold text-mymediumgreen'>
                            Foovante
                        </span>
                    </div>

                    <p className='text-xs bg-white p-4 pt-12 rounded-lg md:text-base lg:text-lg'>
                        Foovante Global is a leading climate tech company committed to
                        helping businesses achieve their sustainability goals, reduce costs,
                        and access carbon credits. We simplify the process of tracking and
                        reducing carbon emissions by combining innovative technology with
                        transparency and accessibility. Whether you are a small business or
                        a large corporation, Foovante makes climate action profitable,
                        scalable, and impactful.
                    </p>

                    <div className='w-full h-16 lg:h-20 rounded-b-xl overflow-hidden lg:hidden'>
                        <img
                            className='w-full h-full object-cover'
                            src='car.png'
                            alt='a green leaf'
                        />
                    </div>
                </div>
            </section>

            {/* the mobile what is carbon offsetting */}
            <section className='px-8 lg:px-48 md:hidden'>
                <h1 className='text-3xl font-semibold text-center pt-12'>
                What is Carbon <br /> Offsetting?
                </h1>
                <div className='pt-4'>
                    <img
                        className='w-full'
                        src='mobileimg1.svg'
                        alt='construction'
                    />
                </div>
                <p className='pt-8 text-sm'>
                    Carbon offsetting is a way for businesses and organizations to counterbalance their
                    carbon emissions by supporting projects that reduce or remove carbon from the atmosphere.
                    These projects can include <strong>renewable energy, reforestation</strong>, and <strong>sustainable agriculture efforts</strong>.
                    By investing in carbon offset programs, you help protect the planet while fulfilling sustainability goals.
                </p>
            </section>

            {/* the desktop what is carbon offsetting*/}
            <section className='hidden md:flex flex-row gap-8 my-20 px-8 lg:px-48 items-center'>
                <div className='pt-4 flex-1'>
                    <img
                        className='w-full'
                        src='mobileimg1.svg'
                        alt='construction'
                    />
                </div>

                <div className='flex-1'>
                    <h1 className='md:text-4xl lg:text-5xl pb-8 text-left font-semibold pt-12'>
                    What is Carbon
                    <br /> Offsetting?
                    </h1>
                    <p className='md:pt-3 lg:pt-6 md:text-base lg:text-xl'>
                    Carbon offsetting is a way for businesses and organizations to counterbalance their
                    carbon emissions by supporting projects that reduce or remove carbon from the atmosphere.
                    These projects can include <strong>renewable energy, reforestation</strong>, and <strong>sustainable agriculture efforts</strong>.
                    By investing in carbon offset programs, you help protect the planet while fulfilling sustainability goals.
                    </p>
                </div>
            </section>

            <section className='my-10 px-8 lg:px-48 text-sm md:text-base lg:text-lg mt-10'>
              <div className='flex flex-col lg:flex-row gap-6'>
                    <div className='flex-1 px-3 lg:px-10 py-4 lg:py-6 bg-white rounded-xl shadow-xl'>
                        <h3 className='text-center uppercase text-lg lg:text-xl pb-2 tracking-wider font-semibold lg:font-bold text-mytealgreen'>
                            Why Should Businesses Get Involved?
                        </h3>
                        <p>
                            As a business, reducing your carbon footprint is not just about doing good for the planet - it&apos;s also an opportunity to <strong>enhance your brand,
                            engage customers</strong>, and <strong>comply</strong> with increasing sustainability regulations. Here&apos;s how engaging in carbon offsetting can benefit your business:
                        </p>
                         <ul>
                          <li> <strong>Enhance Your Reputation</strong>: Show customers, stakeholders, and investors that your business is committed to environmental responsibility.</li>
                          <li> <strong>Meet Regulations</strong>: Stay ahead of global regulations by proactively offsetting your emissions.</li>
                          <li> <strong>Attract Eco-Conscious Consumers</strong>: 70% of consumers prefer brands that are environmentally conscious.</li>
                         </ul>

                    </div>
                    <div className='flex-1 px-3 lg:px-10 py-4 lg:py-6 bg-white rounded-xl shadow-xl'>
                        <h3 className='text-center uppercase text-lg lg:text-xl pb-2 tracking-wider font-semibold lg:font-bold text-mytealgreen'>
                          How Can You Participate in Carbon Offsetting?
                        </h3>
                        <ul>
                          <li> <strong>Calculate Your Emissions</strong>: Start by understanding how much carbon your business produces. This will help you determine how much you need to offset.</li>
                          <li> <strong>Choose the Right Projects</strong>: You can invest in verified, impactful projects that align with your values - whether it&apos;s planting trees or supporting clean energy initiatives.</li>
                          <li> <strong>Offset Your Carbon</strong>: Through Foovante Global, you can easily purchase carbon credits, which fund these sustainable projects, ensuring your carbon emissions are neutralized.</li>
                          <li> <strong>Track Your Impact</strong>: Monitor the positive changes your contributions are making, from forest regeneration to improved local communities.</li>
                        </ul>
                    </div>
              </div>
            </section>


            <section className='my-20 mb-32 md:mb-64 lg:mb-80 relative'>
                <img
                    src='linear.png'
                    alt=''
                    className='w-full h-96 md:h-[47rem] lg:h-[58rem]'
                />
        <div className="flex flex-col gap-8 items-center -mt-[20.5rem] md:-mt-[40rem] lg:-mt-[50rem] px-8 lg:px-48">
            <section className='my-10 text-sm md:text-base lg:text-lg mt-10'>
                    <div className='flex-1 px-3 lg:px-10 py-4 lg:py-6 bg-white rounded-xl shadow-xl'>
                        <h3 className='text-center uppercase text-lg lg:text-xl pb-2 tracking-wider font-semibold lg:font-bold text-mytealgreen'>
                         How Green Project Owners Can Benefit
                        </h3>
                        <p>
                        If you&apos;re a green project owner with a verified, sustainable initiative, you can partner with Foovante Global to make your project part of the solution.
                        By listing your project with us, you gain access to a marketplace of businesses eager to offset their emissions and support projects like yours.
                        This creates a win-win situation for both the planet and your project&apos;s growth.
                        </p>
                    </div>
            </section> 
            <section className='my-10 text-sm md:text-base lg:text-lg mt-10'>
                    <div className='flex-1 px-3 lg:px-10 py-4 lg:py-6 bg-white rounded-xl shadow-xl'>
                        <h3 className='text-center uppercase text-lg lg:text-xl pb-2 tracking-wider font-semibold lg:font-bold text-mytealgreen'>
                        Get Started Today!
                        </h3>
                        <p>
                        Offsetting your carbon footprint is simple and impactful. Whether you&apos;re a business or a green project owner, Foovante Global is here to help you make a difference.
                        Contact us today to learn more about how you can get involved and start contributing to a sustainable future.
                        </p>
                    </div>
            </section> 
        </div>
      </section>
    </div>
  );
}

export default Knowmorepage;