import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import { FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SimpleButton } from "../../components";

const data = [
  {
    img: "/girl.jpg",
    name: "Rebecca Yekple",
    position: "Founder",
      linkedIn: "http://www.linkedin.com/in/rebecca-yekple",
      email: "official.eyram.one@gmail.com",
  },
  {
    img: "/hope.jpg",
    name: "Hope Davids",
    position: "Co-Founder / Software Engineer",
      linkedIn: "https://www.linkedin.com/in/hope-davids-1aa004130/",
      email: "hledavids@gmail.com",
  },
  {
    img: "/samuela.jpg",
    name: "Samuela Ansah",
    position: "Research Analyst",
      linkedIn: "http://www.linkedin.com/in/samuelaansah1311",
      email: "asamuela42@gmail.com",
  },
  {
    img: "/eugenia.jpg",
    name: "Eugenia Mensah",
    position: "Project Management Support",
      linkedIn: "https://www.linkedin.com/in/eugenia-mensah",
      email: "mensaheugenia234@gmail.com",
  },
];

function Aboutpage() {
	useEffect(() => {
		window.scrollTo(0, 0);
	});

	return (
		<div className='bg-slate-100'>
			<section
				className='px-8 lg:px-48 pb-[30%] lg:pb-[20%]'
				style={{
					backgroundImage: "url('/solarbg.jpg')",
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					color: 'white',
				}}
			>
				<h3 className='uppercase text-white text-xl pt-16 pb-6 lg:text-3xl'>
					Who are we?
				</h3>
				<h1 className='text-4xl lg:text-6xl'>
					Your Partner <br /> in Climate <br /> Action
				</h1>
			</section>

			<section className='px-8 lg:px-48'>
				<div className='relative bg-white flex flex-col items-center justify-center mt-[-20px] lg:mt-[-50px] rounded-lg'>
					<div className='absolute bottom-full z-10 flex flex-col justify-center items-center w-24 aspect-square bg-white translate-y-1/2 rounded-full'>
						<img
							src='logoadd.png'
							alt='a green leaf'
						/>
						<span className='text-sm font-semibold text-mymediumgreen'>
							Foovante
						</span>
					</div>

					<p className='text-xs bg-white p-4 pt-12 rounded-lg md:text-base lg:text-lg'>
						Foovante Global is a leading climate tech company committed to
						helping businesses achieve their sustainability goals, reduce costs,
						and access carbon credits. We simplify the process of tracking and
						reducing carbon emissions by combining innovative technology with
						transparency and accessibility. Whether you are a small business or
						a large corporation, Foovante makes climate action profitable,
						scalable, and impactful.
					</p>

					<div className='w-full h-16 lg:h-20 rounded-b-xl overflow-hidden lg:hidden'>
						<img
							className='w-full h-full object-cover'
							src='car.png'
							alt='a green leaf'
						/>
					</div>
				</div>
			</section>

			{/* the mobile making a real section */}
			<section className='px-8 lg:px-48 md:hidden'>
				<h1 className='text-3xl font-semibold text-center pt-12'>
					Making A Real <br /> Difference Together
				</h1>
				<div className='pt-4'>
					<img
						className='w-full'
						src='mobileimg1.svg'
						alt='construction'
					/>
				</div>
				<p className='pt-8 text-sm'>
					At Foovante, we aim to empower businesses to lead the way in climate
					action. We seek to help businesses and organizations around the world
					reduce emissions, support renewable energy projects, and make lasting
					contributions to global sustainability.
				</p>
			</section>

			{/* the desktop making a real section */}
			<section className='hidden md:flex flex-row gap-8 my-20 px-8 lg:px-48 items-center'>
				<div className='pt-4 flex-1'>
					<img
						className='w-full'
						src='mobileimg1.svg'
						alt='construction'
					/>
				</div>

				<div className='flex-1'>
					<h1 className='md:text-4xl lg:text-5xl pb-8 text-left font-semibold pt-12'>
						Making A Real <br /> Difference <br /> Together
					</h1>
					<p className='md:pt-3 lg:pt-6 md:text-base lg:text-xl'>
						At Foovante, we aim to empower businesses to lead the way in climate
						action. We seek to help businesses and organizations around the
						world reduce emissions, support renewable energy projects, and make
						lasting contributions to global sustainability.
					</p>
				</div>
			</section>

			<section className='my-20 px-8 lg:px-48 text-sm md:text-base lg:text-lg mt-14'>
				<div className='flex flex-col gap-4 px-7 lg:px-36 py-4 lg:py-12  bg-white rounded-xl shadow-xl'>
					<h3 className='text-center uppercase text-lg lg:text-xl pb-2 tracking-wider font-semibold lg:font-bold text-mymediumgreen'>
						Let&apos;s Build a Greener World Together
					</h3>
					<p>
						Join our exclusive network of green initiatives and attract global
						funding to amplify your impact. Whether it&apos;s reforestation,
						clean energy, or innovative recycling, showcase your project to
						businesses committed to sustainability, gain tools to grow, and
						connect with a global audience driving climate action.
					</p>
					<div className='dropdown self-center'>
						<SimpleButton
							label='Join Wailist'
							className='peer btn m-1 bg-[#251A45] text-white md:text-lg'
						/>
						<ul
							tabIndex={0}
							className='dropdown-content menu rounded-box z-[1] w-52 p-0 bg-[#251A45] shadow'
						>
							<li className='p-2 hover:bg-black rounded-t-box transition-colors'>
								<Link
									className='text-white'
									target='_blank'
									rel='noreferrer'
									to='https://form.jotform.com/243162753556561'
								>
									Green projects
								</Link>
							</li>
							<li className='p-2 hover:bg-black rounded-b-box transition-colors'>
								<Link
									className='text-white'
									target='_blank'
									rel='noreferrer'
									to='https://form.jotform.com/243242008995560'
								>
									Offsetter
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<section className='my-20 mb-32 md:mb-64 lg:mb-80 relative'>
				<h2 className='lg:text-xl lg:font-bold tracking-widest uppercase text-mymediumgreen text-lg font-semibold text-center mt-16'>
					our team
				</h2>
				<img
					src='linear.png'
					alt=''
					className='w-full h-96 md:h-[47rem] lg:h-[58rem]'
				/>
        <div className="flex flex-col gap-8 items-center -mt-[20.5rem] md:-mt-[40rem] lg:-mt-[50rem] px-8 lg:px-48">
          <span className="hidden md:block md:text-3xl lg:text-4xl font-bold text-white">
            MEET OUR TEAM
          </span>
          <div className="flex flex-col md:flex-row flex-wrap gap-4 justify-center">
            {data.map((item, index) => (
              <div
                key={index}
                className="flex gap-4 items-center p-2 md:max-w-[360px] lg:max-w-lg bg-white text-black shadow-xl rounded-xl"
              >
                <div className=" flex-1 rounded-lg overflow-hidden h-[130px] lg:h-[200px]">
                  <img
                    className="w-full h-full object-cover"
                    src={item.img}
                    alt="this is rebecca"
                  />
                </div>
                <div className="flex-[1.5] flex flex-col gap-2">
                  <p className=" uppercase font-semibold lg:font-bold">
                    {item.name}
                  </p>
                  <span className="font-semibold text-mymediumgreen">
                    {item.position}
                  </span>
                  <span className=" text-sm lg:text-base text-gray-500">
                    {item.about}
                  </span>
                  <div className="flex gap-4 justify-center md:justify-start">
                    <div className="cursor-pointer">
                      <a
                      rel="noreferrer"
                      target="_blank"
                        className="hover:text-mymediumgreen  duration-300"
                        href={item.linkedIn}
                      >
                        <FaLinkedin className="w-5 h-5" />
                      </a>
                    </div>
                    <div className="cursor-pointer">
                      <a
                        className="hover:text-mymediumgreen  duration-300"
                        href={`mailto:${item.email}`}
                      >
                        <MdEmail className="w-5 h-5" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Aboutpage;
