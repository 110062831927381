import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
	SimpleButton,
	RoundedHyperlink,
	SimpleHyperlink,
	RoundedButton,
} from '../../components';

function Feature({ iconObj, caption, contents }) {
	const { iconSrc, iconAlt } = iconObj;

	return (
		<section className='flex flex-col gap-2 my-4'>
			<img
				src={iconSrc}
				alt={iconAlt}
				className='block w-6 aspect-square drop-shadow-xl'
			/>
			<h3 className='text-lg font-semibold'>{caption}</h3>
			<p className='text-gray-500'>{contents}</p>
		</section>
	);
}

Feature.propTypes = {
	iconObj: PropTypes.object.isRequired,
	caption: PropTypes.string.isRequired,
	contents: PropTypes.string,
};

/*
 * Hi Code Reviewer. Sorry but some code snippets were
 * duplicated because the UI guy was having
 * fun designing the UI
 * */
const Home = () => {
	const expectedFeaturesData = [
		{
			iconObj: {
				iconSrc: 'home/fully-integrated.svg',
				iconAlt: 'fully integrated feature',
			},
			caption: 'Foovante Carbon Calculator',
			contents:
				'Our carbon calculator helps businesses track and offset emissions, simplifying sustainability.',
		},
		{
			iconObj: {
				iconSrc: 'home/prebuilt-components.svg',
				iconAlt: 'fully integrated feature',
			},
			caption: 'Veriﬁed Carbon Credit Marketplace',
			contents:
				'The Verified Carbon Credit Marketplace helps businesses offset emissions with certified carbon credits.',
		},
		{
			iconObj: {
				iconSrc: 'home/payments-functionality.svg',
				iconAlt: 'fully integrated feature',
			},
			caption: 'Green Token System',
			contents:
				'Green Token System enables transactions between high emitters and green projects on our blockchain.',
		},
		{
			iconObj: {
				iconSrc: 'home/improved-platform.svg',
				iconAlt: 'fully integrated feature',
			},
			caption: 'Personalized Emission Reduction Strategies',
			contents: 'We offer tailored strategies and education for our customers.',
		},
	];

	useEffect(() => {
		window.scrollTo(0, 0);
	});

	return (
		<>
			<main className="overflow-x-hidden">
				<section className='relative flex flex-col md:flex-row justify-center md:pt-12 md:px-8 lg:px-48'>
					<img
						src='/home/screen-mobile-banner.png'
						alt='a banner reflecting our values'
						className='w-full h-[524px] object-cover md:hidden'
					/>
					<img
						src='/home/screen-large-banner.svg'
						alt='a banner reflecting our values'
						className='hidden md:block order-2 w-1/2 md:py-8 object-cover drop-shadow-xl animate-fadeFromRightWithDelay opacity-0 translate-x-full'
					/>
					<div className='flex flex-col justify-center gap-4 px-8 md:px-0 md:w-1/2'>
						<div className='absolute md:static top-[262px] flex flex-col gap-2 -translate-y-[131px] md:translate-y-0'>
							<span className='text-[55px] lg:text-7xl font-semibold animate-fadeFromBottomSlowWithQuickDelay opacity-0 translate-y-full'>
								Empower
							</span>
							<span className='text-[55px] lg:text-7xl font-semibold animate-fadeFromBottomSlowWithDelay opacity-0 translate-y-full'>
								Sustain
							</span>
							<span className='text-[55px] lg:text-7xl font-semibold animate-fadeFromBottomSlowWithSlowDelay opacity-0 translate-y-full'>
								Earn
							</span>
						</div>
						<p className='pt-4 md:pt-0 animate-fadeFromLeftSlowWithSlowDelay opacity-0 -translate-x-full'>
							Foovante Global enables high CO₂ emission companies to offset
							emissions by supporting green projects and earning carbon credits.
							Join our waitlist to access our seamless calculator and
							marketplace soon!
						</p>
						<div className='flex gap-4 justify-center md:justify-start items-center'>
							<div className='dropdown animate-fadeFromLeftSlowWithSlowDelay opacity-0 translate-x-full'>
              <RoundedButton
									label='Join Waitlist'
									className='peer btn m-1 bg-primary text-white md:text-lg'
								/>
								<ul
									tabIndex={0}
									className='dropdown-content menu rounded-box z-[1] w-52 p-0 bg-primary shadow'
								>
									<li className='p-2 hover:bg-black rounded-t-box transition-colors'>
                  <Link className='text-white' target='_blank' rel='noreferrer' to='https://form.jotform.com/243162753556561'>Green projects</Link>
									</li>
									<li className='p-2 hover:bg-black rounded-b-box transition-colors'>
                  <Link className='text-white' target='_blank' rel='noreferrer' to='https://form.jotform.com/243242008995560'>Offsetter</Link>
									</li>
								</ul>
							</div>
							<div className='animate-fadeFromRightSlowWithSlowDelay opacity-0 translate-x-full'>
								<RoundedHyperlink
									href='/learn-more'
									label='Know More'
									className='bg-[#251A45] text-white md:text-lg'
								/>
							</div>
						</div>
					</div>
				</section>
				<div className="w-full h-16 md:h-36 my-16 lg:my-32 bg-[url('../public/home/green-wavy-shapes.png')] bg-no-repeat bg-cover"></div>
				<section className='flex flex-col gap-4 lg:flex-row px-8 lg:px-48 my-12'>
					<div className='flex flex-col gap-4 lg:flex-row py-8 md:py-0 bg-white rounded-lg drop-shadow-2xl'>
						<h2 className='flex flex-col gap-4 justify-center items-start px-8 md:py-8'>
							<span className='text-primary text-lg tracking-wider'>
								FUNCTIONAL
							</span>
							<span className='text-xl md:text-3xl lg:text-6xl font-bold'>
								Expected Features
							</span>
							<span className='text-xs md:text-md ld:text-lg'>
								Here&apos;s what you can expect from our platform
							</span>
							<SimpleHyperlink
								href='/contact'
								label='Contact Us'
								className='hidden md:block bg-primary text-white md:text-xl'
							/>
						</h2>
						<span className='w-full lg:w-2 h-2 bg-secondary lg:relative lg:top-0 lg:bottom-0 lg:h-auto'></span>
						<div className='grid grid-flow-row grid-cols-1 md:grid-cols-2 gap-4 px-8 py-6'>
							{expectedFeaturesData.map((expectedFeature) => (
								<Feature
									key={expectedFeature.caption}
									iconObj={expectedFeature.iconObj}
									caption={expectedFeature.caption}
									contents={expectedFeature.contents}
								/>
							))}
						</div>
						<SimpleHyperlink
							href='/contact'
							label='Contact Us'
							className='self-center bg-primary text-white md:hidden'
						/>
					</div>
				</section>
				<section className='flex flex-col gap-4 items-center px-8 lg:px-48 py-12'>
					<h2 className='text-primary tracking-wider'>
						ABOUT OUR COMPANY GROUP
					</h2>
					<p className='text-3xl font-bold md:hidden'>
						A Climate-Forward Solution For Your Business
					</p>
					<div className='flex flex-col items-center gap-8 md:flex-row md:justify-between'>
						<div className='relative -z-10 w-full md:w-1/2 lg:w-full'>
							<div className='relative left-0 top-0 w-52 md:w-64 lg:w-96 aspect-square lg:h-64 bg-stone-300/40 backdrop-blur-md rounded-xl'></div>
							<img
								src='home/windmill.jpg'
								alt='a windmill'
								className='absolute left-4 top-6 z-10 w-full object-cover max-w-64 md:max-w-md rounded-xl drop-shadow-xl md:drop-shadow-2xl'
							/>
							<img
								src='home/behind-windmill.png'
								alt='a windmill'
								className='hidden md:block absolute left-4 top-16 w-full object-cover max-w-64 md:max-w-md rounded-xl drop-shadow-xl md:drop-shadow-2xl'
							/>
							<div className='absolute left-24 lg:left-52 top-8 w-52 md:w-64 h-28 lg:h-48 bg-stone-300/40 backdrop-blur-md rounded-xl drop-shadow-2xl'></div>
						</div>
						<div className='flex flex-col gap-2 md:w-1/2 lg:w-auto'>
							<p className='hidden md:block text-3xl lg:text-4xl font-bold'>
								A Climate-Forward Solution For Your Business
							</p>
							<p className='text-gray-500 text-xs lg:text-lg'>
								Foovante empowers businesses to lower their carbon footprint,
								enhance profitability, and strengthen brand reputation. Our
								solutions ensure compliance with industry regulations, unlock
								access to carbon credits, and position you as a leader in
								sustainability.
							</p>
							<Link
								to='/learn-more'
								className='flex gap-2 items-center self-start text-xl text-primary'
							>
								<span>More Info</span>
								<svg
									width='47'
									height='15'
									viewBox='0 0 47 15'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										className='fill-primary'
										d='M1.31818 7.38182H45.6363M45.6363 7.38182C45.6363 7.38182 40.3323 3.89359 39.2545 1M45.6363 7.38182C45.6363 7.38182 40.9474 10.6594 39.2545 13.7636'
										stroke='#2CC295'
										strokeLinecap='square'
									/>
								</svg>
							</Link>
						</div>
					</div>
				</section>
				<section className='flex flex-col gap-4 items-center px-8 lg:px-48 py-12 md:pt-32'>
					<div className='flex flex-col gap-2 items-center'>
						<h2 className='flex gap-2 justify-center items-center w-full text-3xl font-bold'>
							<span className='inline-block w-9 md:w-12 h-px bg-gray-400'></span>
							<span className='text-primary'>Get Involved</span>
							<span className='inline-block w-9 md:w-12 h-px bg-gray-400'></span>
						</h2>
					</div>
					<p className='text-xs text-primary tracking-wider md:hidden'>
						LET&apos;S HAVE YOU ON BOARD!
					</p>
					<p className='text-3xl font-bold md:hidden'>Be Part Of The Change</p>
					<div className='flex flex-col md:flex-row gap-8 md:py-12 md:justify-between lg:justify-start'>
						<img
							src='home/be-part-of-the-change.svg'
							alt='be part of the change'
							className='w-10/12 md:w-1/2 drop-shadow-xl md:max-w-sm md:order-2'
						/>
						<div className='flex flex-col gap-4 md:order-1 md:w-1/2 lg:w-auto md:justify-center'>
							<p className='hidden text-xs text-primary tracking-wider md:block'>
								LET&apos;S HAVE YOU ON BOARD!
							</p>
							<p className='hidden text-3xl font-bold md:block'>
								Be Part Of The Change
							</p>
							<p className=' text-xs lg:text-lg text-gray-500'>
								Make a lasting impact on the planet with Foovante. Tailored for
								small businesses, medium and large corporations, our solutions
								drive sustainability and growth.
							</p>
							<div className='hidden /*flex*/ gap-4 justify-center items-center md:justify-start w-full px-4 md:px-0'>
								<SimpleHyperlink
									href='#'
									label='Partner with Us'
									className='w-1/2 md:w-auto bg-primary text-white md:text-lg'
								/>
								<SimpleHyperlink
									href='#'
									label='Volunteer'
									className='w-1/2 md:w-auto bg-[#251A45] text-white md:text-lg'
								/>
							</div>
						</div>
					</div>
				</section>
				<section className='flex flex-col gap-4 items-center px-8 lg:px-48 py-12'>
					<div className='flex flex-col gap-8 justify-center pb-12 md:flex-row'>
						<div className='flex flex-col gap-4 justify-center'>
							<h2 className='text-primary tracking-wider md:text-lg'>
								OUR PARTNERS
							</h2>
							<p className='text-3xl md:text-4xl font-bold'>
								Partner With Foovante Global
							</p>
							<p className='text-gray-500 md:text-lg'>
								Our partners play a vital role in creating and delivering
								impactful solutions, ensuring every project is presented with
								the highest standards of professionalism and excellence.
							</p>
						</div>
						<div className='flex gap-4 lg:max-w-sm lg:'>
							<img
								src='home/partner-alx-ventures.png'
								alt='alx ventures is our partner'
								className='w-1/2 object-contain'
							/>
							<img
								src='home/partner-paybox.png'
								alt='paybox is our partner'
								className='w-1/2 object-contain'
							/>
						</div>
					</div>
					<SimpleHyperlink
						href='#'
						label='Partner With Us'
						className='hidden bg-primary text-white md:self-start md:text-lg'
					/>{' '}
					{/* TODO: This href is subject to change */}
				</section>
				<section className='flex gap-8 justify-center items-center px-8 lg:px-48 py-12 bg-white md:flex-col lg:flex-row'>
					<div className='flex flex-col gap-8 items-start md:order-2 lg:order-2'>
						<p className='text-gray-600 text-2xl font-semibold'>
							Lead the Change, Embrace Sustainability
						</p>
						<p className='text-gray-500'>
							Foovante empowers businesses to embrace sustainability, reduce
							carbon footprints, and lead the way in climate action while
							driving growth and innovation.
						</p>
						<form className='hidden /*flex*/ flex-col md:flex-row gap-4 items-center w-full'>
							<label className='input input-bordered border-gray-400 flex items-center gap-2'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									viewBox='0 0 16 16'
									fill='currentColor'
									className='h-4 w-4 opacity-70'
								>
									<path
										d='M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z'
										className='fill-gray-400'
									/>
									<path
										d='M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z'
										className='fill-gray-400'
									/>
								</svg>
								<input
									type='text'
									className='grow'
									placeholder='Enter your email address'
								/>
							</label>
							<SimpleButton
								label='Subscribe'
								className='bg-primary text-white md:text-lg'
							/>
						</form>
					</div>
					<img
						src='home/subscription-image.svg'
						alt='subscribe to our newsletter'
						className='hidden md:block drop-shadow-x'
					/>
				</section>
			</main>
			<p></p>
		</>
	);
};

export default Home;
